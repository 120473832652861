$color: (
  logo: #6358fe,
  "text": #f3f3f3,
  "text-light": #f3f3f380,
  "text-dark": #303030,
  "bg": #131526,
  "bg-light": #171931,
  "bg-light-2": #262a4d,
  "cta": #f0a000,
);

/* $color: (
//   logo: #6358fe,
//   "text": #303030,
//   "text-light": #b1b1b1,
//   "text-dark": #000000,
//   "bg": #f0f0f0,
//   "bg-light": #fbfbfb,
//   "cta": #f0a000,
// );
*/


/* dz-index(s)
0-25: Interactive elements

26-50: Header, Footer buttons and all

51-100: Modals, tooltips and other top-level elements

*/

// Font sizes
$fs-2xsm: 0.625rem; // Extra Small
$fs-xsm: 0.75rem; // Extra Small
$fs-sm: 0.875rem; // Small
$fs-md: 1rem; // Medium
$fs-normal: 1.125rem; // Normal
$fs-lg: 1.25rem; // Large
$fs-xlg: 1.5rem; // Extra Large
$fs-2xlg: 1.75rem; // 2x Extra Large
$fs-3xlg: 2rem; // 2x Extra Large

// Spacing
$space-xsm: 0.25rem; // Extra Small
$space-sm: 0.5rem; // Small
$space-md: 1rem; // Medium
$space-normal: 1.25rem; // Normal
$space-lg: 1.5rem; // Large
$space-xlg: 2rem; // Extra Large
$space-2xlg: 2.5rem; // 2x Extra Large


@mixin v_center() {
  display: flex;
  align-items: center;
}

@mixin center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex_col() {
  display: flex;
  flex-direction: column;
}

@mixin absCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // outline: 1px solid red;
}

html {
  font-size: 12px;
  line-height: 1.5;
  font-family: "Inria Sans", sans-serif;
  cursor: default !important;
}

html,
body,
#root {
  min-height: 100vh;
  background-color: map-get($color, "bg");
  color: map-get($color, "text");
  cursor: url('../public/assets/icons/pointers/cursor.cur'), auto;
}

html {
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: map-get($color, "bg");
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: rgba(map-get($color, "logo"), 50%);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: map-get($color, "logo");
  }
}

body {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;

  &:not(.no-underline):hover {
    text-decoration: underline;
  }
}

button,
input {
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  // background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button:not(.disabled):active {
  scale: 0.98;
}

button:disabled {
  pointer-events: none;
}

img {
  display: block;
  max-height: 100%;
}

.wrapper {
  display: block;
  width: 100%;
  padding-inline: 1rem;
  margin-inline: auto;
}

#root {
  @include flex_col();
}

header {
  height: 64px;
  border-bottom: 1px solid map-get($color, 'bg-light');
  background-color: map-get($color, 'bg-light');

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;

    h1 {
      flex: 1;
      font-size: 1.5rem;
      font-weight: 100;
      @include v_center();
      color: map-get($color, "text-light");
      line-height: 1;
      font-weight: 600;

      a {
        background: linear-gradient(120deg, #ca57ff 30%, #ff638f);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        animation: animateGradient 3s infinite;
        display: inline-block;
        background-size: 400% auto;
      }

      &:has(:is(input:focus, :not(input:placeholder-shown))) {
        // color: map-get($color, "text");
      }
    }

    form {
      $input_color: map-get($color, "text-light");
      display: flex;
      width: 100%;
      max-width: 550px;
      position: relative;
      margin-left: 1rem;

      input[type="text"] {
        font-size: 1.25rem;
        font-weight: 500;
        padding: 8px 15px;
        width: 100%;
        // max-width: 480px;
        background-color: transparent;
        color: $input_color;
        border: 1px solid $input_color;
        caret-color: map-get($color, "text");
        border-radius: 4px;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-weight: 300;
          color: $input_color;
        }
      }

      button[type="reset"] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 1.5rem;
        cursor: pointer;

        span {
          display: block;
          transform: rotate(45deg);
          color: $input_color;

          &:hover {
            color: map-get($color, "text");
          }
        }
      }
    }
  }
}

.stage {
  display: flex;
  align-items: stretch;
  flex: 1;
  max-height: calc(100vh - 64px);
  padding: 10px;
  gap: 10px;
  overflow: auto;

  aside {
    flex: 3.5;
    position: sticky;
    top: 0;
  }

  hr {
    border: none;
    border-right: 1px solid map-get($color, "text-light");
  }

  main {
    flex: 6.5;
  }
}

aside {
  .side_panel {
    // background-color: map-get($color, 'bg-light');
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 5px 0 10px #0001;

    .clips_container {
      max-height: 445px;
      min-height: 100px;
      overflow-y: auto;
      padding-right: 5px;

      &::-webkit-scrollbar {
        width: 5px;
      }

      .clip_item {
        p {
          flex: 1;
          color: map-get($color, 'text-light');

          span {
            color: map-get($color, 'text');
          }
        }

        .preview_btn {
          cursor: pointer;
          padding-top: 2px;
          padding-left: 2px;
          border-radius: 8px;
          width: 25px;
          opacity: 0.4;
          aspect-ratio: 1 / 1;

          &:hover {
            outline: 1px solid #fff;
          }
        }

        .delete_btn {
          font-size: 20px;
          aspect-ratio: 1 / 1;
          width: 25px;
          opacity: 0.4;
          padding-left: 2.5px;
          overflow: clip;

          &:hover {
            outline: 1px solid #f87171;
          }
        }

        &:hover {
          .delete_btn {
            opacity: 1;
          }

          .preview_btn {
            opacity: 1;
          }
        }
      }

      .clip_item_skeleten {}
    }

    .clip_items_skeleten {
      span {
        line-height: 1;
      }
    }

    .video_options {
      min-height: 145px;
      margin-top: auto;
      font-size: 14px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "a b"
        "c d";
      gap: 10px;

      .setting_option {
        background-color: map-get($color, 'bg-light-2');
        padding: 10px;
        border-radius: 10px;
        display: flex;
        align-items: center;

        &.video_lang * {
          cursor: url('../public/assets/icons/pointers/cursor.cur'), auto;
        }

        &.is_subs {
          grid-area: b;

        }

        &.sub_styles {}

        &.dubbing {}
      }

    }

    .action_btns {
      .download {

        background-color: map-get($color, 'logo');
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 56;
  background-color: #0008;
  backdrop-filter: blur(3px);
  display: grid;
  place-items: center;

  .modal_container {
    background-color: map-get($color, 'bg-light');
    border-radius: 20px;
    outline: 4px solid map-get($color, 'text');
    outline-offset: 3px;

    width: 72%;
    height: 75%;
  }
}

// Clip page
main {
  .video_container {
    @include flex_col();
    position: relative;
    padding: 5px 10px 0 0;

    .iframe_container {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9 !important;
      outline: 1px solid #35374a;
      outline-offset: 2px;
      overflow: hidden;
      border-radius: 4px;

      #player,
      .frame_crop {
        width: 100% !important;
        height: 100% !important;
      }

      .frame_crop {
        position: absolute;
        top: 0;
        left: 0;
        // z-index: 10;

        .box_ratio {
          height: 100%;
          aspect-ratio: 9/16;
          position: relative;
          outline: 1px solid cyan;
          outline-offset: -1px;
          border-radius: 4px;

          &::before,
          &::after {
            content: '';
            position: absolute;
            left: 100%;
            width: 800px;
            height: 100%;
            background-color: #0008;
            backdrop-filter: blur(3px);
            pointer-events: none;
          }

          &::before {
            left: unset;
            right: 100%;
          }
        }

        display: none;

        &.active {
          display: block;
        }
      }
    }

    .playback_controls {
      margin-top: 1rem;

      .progress_bar {
        height: 20px;
        position: relative;
        background-color: #c6c6c6;

        .play_range {
          background-color: map-get($color, "text");
          height: 100%;
          position: absolute;
          top: 0;

          width: 100%;
          left: 0;
          cursor: default !important;

          &.moving {
            cursor: grabbing !important;
          }

          .progress {
            background-color: red;
            height: 100%;
            position: absolute;
            left: 0;
            pointer-events: none;
          }

          .play_range_controls {
            position: absolute;
            top: 0%;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;

            .resize_left,
            .resize_right {
              cursor: ew-resize;
              pointer-events: all;
              position: absolute;
              height: 150%;
              width: 5px;
              border: 1px solid #fff;
              background-color: red;
              left: 0;
              top: 50%;
              transform: translate(-100%, -50%);
              user-select: none;

              span {
                position: absolute;
                left: 0;
                top: calc(100% + 5px);
                background-color: #000;
                color: #fff;
                font-weight: 600;
                font-size: 14px;
                padding: 4px;
                border-radius: 4px;
                box-shadow: 0 10px 50px #000;
                pointer-events: none;
                opacity: 0;
                transition-delay: 0.2s;
                z-index: 2;
                border: 1px solid map-get($color, 'text-light');
              }
            }

            .resize_right {
              left: calc(100% + 5px);

              span {
                bottom: calc(100% + 5px);
                right: 0;
                left: unset;
                top: unset;
              }
            }
          }
        }

        &:hover {
          :is(.resize_left, .resize_right) span {
            opacity: 1 !important;
            transition-delay: 0s !important;
          }
        }
      }

      .time_display {
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
        gap: 3px;
      }
    }

    .settings {
      background-color: map-get($color, 'bg-light');
      position: absolute;
      left: 5px;
      top: 0;
      width: 80px;
      height: 100%;
      border-radius: 8px;

      @include flex_col();
      padding: 5px 10px;
    }
  }

  .range_input {
    @include v_center();
    background: map-get($color, 'bg-light-2');
    padding: 20px;
    border-radius: 8px;

    .time_input {
      flex: 1;
      display: flex;
      gap: 10px;

      &>div.input_field {
        flex: 1;
        height: 100%;
        @include flex_col();
        position: relative;

        label {
          color: map-get($color, 'text');
          min-width: 45px;
          font-weight: 500;
        }

        input {
          align-self: stretch;
          flex: 1;
        }

        &::after {
          content: 'sec';
          position: absolute;
          bottom: 9px;
          right: 10px;
          pointer-events: none;
        }
      }

      input {
        padding: 8px 15px;
        padding-right: 40px;
        background-color: map-get($color, "bg");
        border: 1px solid map-get($color, "text-light");
        caret-color: map-get($color, "text");
        color: map-get($color, "text");
        border-radius: 4px;

        &:focus {
          outline: none;
        }
      }

      input:invalid {
        background-color: #ff6d6d;
        color: #fff;
      }
    }

    .add_btn {
      @include center();
      padding: 9px 20px;
      align-self: flex-end;
      margin-left: 20px;
      border-radius: 4px;
      background-color: map-get($color, 'cta');
      color: map-get($color, 'bg-light');
      text-transform: capitalize;
      font-weight: 800;
    }
  }

  .crop_frame_inputs {
    background: #262a4d;
    padding: 20px;
    border-radius: 8px;

    .crop_toggle_input {}
  }
}

.landing_page {
  position: relative;

  section.hero {
    text-align: center;
    padding-top: 150px;

    h1 {
      font-weight: 600;
      font-size: calc(2.5 * $fs-lg);
      line-height: 1.5;

      background: -webkit-linear-gradient(left, #faedff, #d981ff, #ff7ba3, #faedff);
      background-size: 1600px 200px;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: animationgradienttitle 3s infinite;

      @keyframes animationgradienttitle {
        0% {
          background-position: 0 1600px;
        }

        100% {
          background-position: 1600px 0;
        }
      }
    }

    h1+.sub_heading {
      font-size: calc(2 * $fs-lg);
      color: #f3f3f3;
      font-weight: 600;
    }

    p {
      font-size: $fs-lg;
      margin-top: $space-sm;

      a {
        color: map-get($color, logo);
        text-decoration: underline;
        text-underline-offset: $space-sm;
      }

      span {
        font-weight: 900;
      }
    }

    h1,
    p {
      position: relative;
      z-index: 2;
    }

    span.bg-oval {
      position: absolute;
      top: 80vh;
      left: 80%;
      z-index: 1;
      display: block;
      width: 250px;
      height: 250px;
      background: rgb(91, 83, 201);
      filter: blur(150px);
      border-radius: 50%;

      &.oval2 {
        top: 200px;
        left: 10%;
        background: #ba4c7e;
      }
    }
  }

  section.IO {
    div.input {
      margin-block: calc(1.5 * $space-2xlg);

      form {
        width: fit-content;
        max-width: min(100%, 537px);
        margin-inline: auto;
        position: relative;

        div.url {
          background-color: #fff;
          padding: $space-sm;
          border-radius: $space-sm;
          @include v_center();

          &:has(:is(input:focus, button:focus)) {
            outline: 2px solid map-get($color, logo);
            box-shadow: 0 0 10px #ffffff;
          }

          &>img {
            width: 36px;
          }

          input {
            width: clamp(200px, 450px, 450px);
            padding-inline: $space-normal;
            font-size: $fs-lg;
            font-weight: 600;
            outline: none;
            color: map-get($color, 'text-dark');
          }

          button {
            display: grid;
            border-radius: 6px;
            cursor: pointer;
            background-color: #1d1034;
            outline-offset: 2px;
          }
        }

        &:has(input:placeholder-shown)::after {
          content: "Only youtube videos for now!";
          position: absolute;
          top: calc(100% + $space-sm);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  section.app_preview {
    margin-top: 100px;
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 30px 20px;

    .preview {
      box-shadow: 0 0px 70px #fff1;
      overflow: hidden;
      background: #ececec;
      padding: 2px;
      background-size: 400% auto;
      border-radius: 10px;
      width: fit-content;
      position: relative;
      transition: 0.4s;

      video {
        border-radius: 10px;
        height: 100%;
      }

      p {
        position: absolute;
        top: 20px;
        right: 30px;
        opacity: 0;

        div {
          background-color: #fff6;
          backdrop-filter: blur(10px);
          padding: 5px 15px;
          border-radius: 5px;
          font-size: 20px;
          cursor: pointer;
          font-weight: 600;

          &:hover {
            background-color: #000;
          }

          span {
            display: block;
            transform: rotate(-70deg);
            user-select: none;
          }
        }
      }

      &:hover p {
        opacity: 1;
        transition-delay: 1s;
        transition: 0.3s ease-out;
      }
    }

    &:has(.preview:hover) {
      .preview:hover{
        scale: 1.1;
        position: relative;
        z-index: 1;
        transition: 0.4s;
        transform-origin: top left;
        top: 10px;
        left: 10px;
        padding: 4px;

        &:nth-of-type(2){
          transform-origin: top right;
          left: unset;
          right: 10px;
        }
        &:nth-of-type(3){
          transform-origin: bottom left;
          top: unset;
          bottom: 10px;
        }
        &:nth-of-type(4){
          transform-origin: bottom right;
          top: unset;
          left: unset;
          bottom: 10px;
          right: 20px;
        }
      }
      &> :not(.preview:hover) {
        filter: brightness(0.5);
      }
    }

  }

  .video_count {
    span {
      background: linear-gradient(120deg, #ca57ff 30%, #ff638f);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      animation: animateGradient 3s infinite;
      background-size: 400% auto;
    }

  }

  .features_list {
    .features {
      display: flex;
      flex-direction: column;
      padding-inline: 100px;
      gap: 80px;

      .feature {
        // $color: var(--color);
        padding: 40px 40px 0 40px;
        // box-shadow: 0 0 20px $color;
        background-image: linear-gradient(45deg, #9D8DF180, #8EE3F540);
        border: 3px solid map-get($color, 'text-light');
        border-radius: 30px;
        display: flex;
        align-items: center;
        gap: 40px;

        &:nth-of-type(even) {
          flex-direction: row-reverse;
        }

        .text_content {
          flex: 5;
          @include flex_col();
          gap: 20px;
          align-items: flex-start;

          h4 {
            font-size: 1.75rem;
            font-weight: 600;
          }

          h4+p {
            font-size: 1.2rem;
            line-height: 1.7;
            letter-spacing: 1;
            margin-bottom: 40px;
            color: #c3d3d9;
          }

          button {
            align-self: flex-end;

          }
        }

        .image_content {
          flex: 5;
          height: 300px;
          border-radius: 10px 10px 0 0;
          // overflow: hidden;
          // box-shadow: 0 -5px 50px #0008;

          img {
            width: 100%;
            max-height: unset;
            position: relative;
          }
        }

        &.clipping .image_content img {
          top: -30px;
        }

        &.cropping .image_content img {
          top: -40px;
          left: -20px;
        }

        &.merging .image_content img {
          top: -10px;
        }
      }
    }
  }

}

footer {
  padding-block: 20px;
  color: map-get($color, "text");
  margin-top: 100px;
  border-top: 1px solid rgba(map-get($color, "text"), 30%);
  text-align: center;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    color: map-get($color, 'text-light');
  }
}

@media only screen and (min-width: 575px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14px;
  }

  header h1 input {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 992px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    font-size: 16px;
  }

  // .wrapper {
  //   // max-width: 1150px;
  //   // padding-inline: unset;
  // }
}

@media only screen and (min-width: 1440px) {
  .wrapper {
    max-width: 1400px;
  }
}

.toast {
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 40px;
  border-radius: 0.5vw;
  color: #fff;
  background-color: #00bc5e;
  user-select: none;
  box-shadow: 0 5px 10px #0002;
  text-transform: capitalize;

  position: fixed;
  top: 80px;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  animation: toastin 0.2s ease-in;

  &.error {
    background-color: rgb(255, 77, 77);
  }

  &>p {
    pointer-events: none !important;
  }

  @keyframes toastin {
    0% {
      top: 70px;
      opacity: 0;
    }

    100% {
      top: 80px;
      opacity: 1;
    }
  }
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: loading 1s infinite linear;
}

@keyframes loading {
  to {
    transform: rotate(1turn)
  }
}

.disabled {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 26;
    background-color: #95959580;
    cursor: not-allowed;
    user-select: none;
  }

  &.loading {
    &::after {
      cursor: wait;
    }
  }
}

.feedback_button {
  padding: 6px 10px;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  span {
    font-size: 1.4rem;
    line-height: 100%;
    position: relative;
    top: 2px;
  }

  &::before {
    content: "";
    background: linear-gradient(45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing-feedback_button 20s linear infinite;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &::after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #222;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
}

@keyframes glowing-feedback_button {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.MuiTooltip-tooltip {
  background: #000 !important;
}

@keyframes animateGradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.clip_btn {
  padding: 20px 80px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 2px;

  &::after,
  &::before {
    border-radius: 100px;
  }
}